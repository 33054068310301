<template>
  <div v-if="$store.state.company != null">
    <div class="hero-wrap style3 bg-squeeze">
      <div class="hero-slider-one">
        <div class="hero-slide-item" v-for="slide in getCarouselItems()" :key="slide.id">
          <div class="container">
            <div class="row gx-5 align-items-center">
              <div class="col-md-6">
                <div
                    class="hero-content"
                    data-speed="0.10"
                    data-revert="true"
                >
                  <span>{{ slide.subtitle }}</span>
                  <h1>{{ slide.heading }}</h1>
                  <p>{{ slide.desc }}</p>
                  <div class="hero-btn">
                    <router-link to="/" class="btn style1"
                    >Get Started</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="hero-img-wrap">
                  <img :src="slide.image" alt="Image" class="hero-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data: () => ({
    isPopup: false,
  }),
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
    getCarouselItems() {
      return [
        {
          id: 1,
          subtitle: this.$store.state.company.small_title,
          heading: this.$store.state.company.big_title,
          desc: this.$store.state.company.description,
          image: this.$store.state.url + this.$store.state.company.hero_image,
        },
      ]
    }
  },
});
</script>

