<template>
    <div class="pricing-table-wrap pt-100 pb-75" v-if="$store.state.trainings != null">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <span>Our Pricing Plan</span>
                        <h2>We Charge As Little As Possible</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in this.$store.state.trainings" :key="item.id">
                    <div class="pricing-table style1">
                        <div class="pricing-header">
                            <h2><img :src="$store.state.url + item.logo" alt="Image">{{item.title}}</h2>
                            <div class="pricing-header-tag">
                                <h3 style="font-size: 26px">{{ item.price_value }}<span>{{ item.duration_value }}</span></h3>
                            </div>
                        </div>
                        <div class="pricing-features">
                            <ul class="list-style">
                                <li class="checked" v-for="item1 in item.training_titles.split(';')" :key="item1">
                                  <i class="ri-check-line"></i>
                                  {{ item1.toString().trim() }}
                                </li>
                            </ul>
                        </div>

                        <div class="purchase-pricing">
                            <router-link to="/open-account" class="btn style1">Start Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PricingPlan'
}
</script>