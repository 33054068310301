<template>
    <div class="feature-wrap pt-100 pb-75 bg-albastor" v-if="$store.state.services != null">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>{{$store.state.services.small_title}}</span>
                <h2>{{$store.state.services.title}}</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in this.$store.state.services.services" :key="item.id">
                    <div class="feature-card style1">
                        <div class="feature-info">
                            <div class="feature-title">
<!--                                <span>-->
<!--                                  <img src="../../assets/images/feature/feature-icon-4.png" alt="Image">-->
<!--                                </span>-->
                                <span class="service-icon">
                                  <i :class="item.icon_name" style="color: #E66239"></i>
                                </span>
                                <h3>{{ item.title }}</h3>
                            </div>
                            <p>{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>